import { NgClass, NgStyle } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, HostListener, OnInit, input, signal, viewChildren } from '@angular/core';

export interface StepperProps {
  labels: { title: string, subtitle?: string }[],
  activeIndex: number,
  isVerticle?: boolean,
  titleFontSize?: string,
  subtitleFontSize?: string,
  width?: string,
  height?: string,
  activeColor?: string,
  inActiveColor?: string,
  subtitleColor?: string
}

@Component({
  selector: 'app-stepper',
  standalone: true,
  imports: [NgClass, NgStyle],
  templateUrl: './stepper.component.html',
  styleUrl: './stepper.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class StepperComponent implements OnInit, AfterViewInit {

  props = input.required<StepperProps>();
  stepsRef = viewChildren<ElementRef<HTMLDivElement>>('step');
  stepperTrackWidth = signal<{ marginLeft: number, marginRight: number }>({ marginLeft: 0, marginRight: 0 });
  stepperTrackHeight = signal<{ marginTop: number, marginBottom: number }>({ marginTop: 0, marginBottom: 0 });

  @HostListener('window:resize')
  onResize(){
    this.renderStepper();
  }

  renderStepper(){
    if (!this.props().isVerticle) {
      this.stepperTrackWidth.set({ marginLeft: (this.stepsRef()[0].nativeElement.offsetWidth / 2), marginRight: (this.stepsRef()[this.stepsRef().length - 1].nativeElement.offsetWidth / 2) });
    } else {
      this.stepperTrackHeight.set({ marginTop: this.stepsRef()[0].nativeElement.offsetHeight / 2, marginBottom: (this.stepsRef()[this.stepsRef().length - 1].nativeElement.offsetHeight / 2) });
    }
  }

  ngOnInit(): void {
    if (this.props().activeIndex > this.props().labels.length -1){
      throw new Error('activeIndex value is greater then labels array length.');
    }

    if(this.props().activeColor && !this.props().inActiveColor){
      throw new Error('inActiveColor is required when activeColor is passed.');
    }

    if(this.props().inActiveColor && !this.props().activeColor){
      throw new Error('activeColor is required when inActiveColor is passed.');
    }
  }

  ngAfterViewInit(): void {
    this.renderStepper();
  }

}