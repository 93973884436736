<ul class="stepper-wrapper" [ngClass]="{'stepper-wrapper-verticle': props().isVerticle}" [ngStyle]="{'width': props().width, 'height': props().height}">
    @for (item of props().labels; track item.title; let i = $index) {
        <li #step class="step" [ngClass]="{'step-verticle': props().isVerticle}">
            <div class="step-dot" [ngClass]="{'step-dot-active': i == props().activeIndex ,'step-dot-complete': i < props().activeIndex}">
                @if (i < props().activeIndex) {
                    <i class="ph-bold ph-check"></i>
                }
            </div>
            <div class="step-text-wrapper">
                @if (props().activeColor && props().inActiveColor) {
                    <div class="step-text font-weight-600" [ngStyle]="{'color':i == props().activeIndex ? props().activeColor : props().inActiveColor, 'font-size': props().titleFontSize}">{{item.title}}</div>
                } @else {
                    <div class="step-text font-weight-600" [ngStyle]="{'color':i == props().activeIndex ? 'var(--neonBlueColor)' : 'var(--gray600)', 'font-size': props().titleFontSize}">{{item.title}}</div>
                }
                @if (props().labels[i].subtitle) {
                    <div class="step-sub-text" [ngStyle]="{'color': props().subtitleColor, 'font-size': props().subtitleFontSize}">{{item.subtitle}}</div>
                }
            </div>
        </li>
    }
    <div class="stepper-track" [ngClass]="props().isVerticle ? 'stepper-track-verticle': 'stepper-track-horizontal'" [ngStyle]="{'margin-left': stepperTrackWidth().marginLeft+'px', 'margin-right': stepperTrackWidth().marginRight+'px', 'margin-top': stepperTrackHeight().marginTop +'px', 'margin-bottom': stepperTrackHeight().marginBottom+'px', '--stepperWidth': (stepperTrackWidth().marginRight+stepperTrackWidth().marginLeft)+'px', '--stepperHeight': (stepperTrackHeight().marginTop+stepperTrackHeight().marginBottom)+'px'}">
        <div class="stepper-progress" [ngStyle]="{'--stepperProgress':  100 / (props().labels.length - 1) * (props().activeIndex) +'%'}"></div>
    </div>
</ul>
